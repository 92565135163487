<template>
  <section class="general-title-area">
    <div v-if="state.id" :id="state.id" class="anchor"></div>
    <h2>{{ state.title }}</h2>
  </section>
</template>

<script>
import { reactive, defineComponent } from "vue";

export default defineComponent({
  name: "GeneralTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const state = reactive({
      title: props.title,
      id: props.id,
    });
    return { state };
  },
});
</script>

<style lang="scss" scoped>
h2 {
  color: #001926;
  margin: 40px 0 30px;
  text-align: center;
  font-size: 40px;
}
.general-title-area {
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  @media (min-width: 1140px) {
    max-width: 1140px;
  }
}
.anchor {
  padding-top: 80px;
  margin-top: -80px;
}
</style>
