import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import TopPage from "../pages/TopPage.vue";
import MusicPage from "../pages/MusicPage.vue";
import AboutPage from "../pages/AboutPage.vue";
import AboutWebPage from "../pages/AboutWebPage.vue";
import NotFoundError from "../pages/NotFoundError.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: TopPage,
  },
  {
    path: "/music",
    name: "music",
    component: MusicPage,
  },
  {
    path: "/about",
    name: "about",
    component: AboutPage,
  },
  {
    path: "/site",
    name: "site",
    component: AboutWebPage,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFoundError,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

export default router;
