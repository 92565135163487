<template>
  <section class="main-visual">
    <div class="title-text container">
      <h2>404</h2>
      <h4>Page not found</h4>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotFoundError",
  components: {},
  mounted() {
    const noindex = document.createElement("meta");
    noindex.name = "robots";
    noindex.content = "noindex";
    document.title = "404 Not found";
    document
      .querySelector('meta[property="description"]')
      .setAttribute("content", "");
    document.head.appendChild(noindex);
  },
});
</script>

<style lang="scss" scoped>
.main-visual {
  background-attachment: fixed;
  position: relative;
  height: 100vh;
  .title-text {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #001926;
    z-index: 100;
    h4 {
      font-size: 12px;
      @media (min-width: 400px) {
        font-size: 16px;
      }
      @media (min-width: 750px) {
        font-size: 18px;
        padding-bottom: 4px;
      }
      @media (min-width: 900px) {
        font-size: 20px;
        padding-bottom: 4px;
      }
      @media (min-width: 1000px) {
        font-size: 22px;
        padding-bottom: 4px;
      }
    }
    h2 {
      font-size: 20px;
      @media (min-width: 400px) {
        font-size: 30px;
      }
      @media (min-width: 750px) {
        font-size: 40px;
      }
      @media (min-width: 900px) {
        font-size: 50px;
      }
      @media (min-width: 1000px) {
        font-size: 60px;
      }
    }
  }
}
</style>
