import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e14a941a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "characteristic-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoColumnImageAndDescription = _resolveComponent("TwoColumnImageAndDescription")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_TwoColumnImageAndDescription, {
      contents: _ctx.state.characteristic
    }, null, 8, ["contents"])
  ]))
}