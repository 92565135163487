<template>
  <HeaderMenu />
  <main>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </main>
  <FooterArea />
</template>

<script>
import FooterArea from "@/components/common/FooterArea";
import HeaderMenu from "@/components/common/HeaderMenu";
export default {
  components: { FooterArea, HeaderMenu },
};
</script>

<style>
* {
  margin: 0;
  font-family: sans-serif;
}
main {
  flex: 1;
}
html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #2f4858;
}
html::-webkit-scrollbar {
  width: 11px;
}
html::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
html::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
</style>
