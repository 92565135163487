
import { defineComponent, onMounted, reactive } from "vue";
export default defineComponent({
  name: "HeaderMenu",
  setup() {
    const state = reactive({
      scrolledNav: false,
      mobile: false,
      mobileNav: false,
      windowWidth: 0,
    });

    const toggleMobileNav = () => {
      state.mobileNav = !state.mobileNav;
    };

    const updateScroll = () => {
      const scrollPosition: number = window.scrollY;
      if (scrollPosition > 50) {
        state.scrolledNav = true;
        return;
      }
      state.scrolledNav = false;
    };

    const checkScreen = () => {
      state.windowWidth = window.innerWidth;
      if (state.windowWidth <= 750) {
        state.mobile = true;
        return;
      }
      state.mobile = false;
      state.mobileNav = false;
    };

    window.addEventListener("resize", checkScreen);

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    return {
      state,
      toggleMobileNav,
      updateScroll,
      checkScreen,
    };
  },
});
