
import { defineComponent, reactive } from "vue";
import SimpleLinkCarousel from "@/components/common/SimpleLinkCarousel.vue";
import originalSongs from "@/data/original_songs.json";
export default defineComponent({
  name: "TopMusicList",
  components: { SimpleLinkCarousel },
  setup() {
    const state = reactive({
      originalSongs: originalSongs,
    });
    return { state };
  },
});
