
import TwoColumnImageAndDescription from "@/components/common/TwoColumnImageAndDescription.vue";
import characteristic from "@/data/characteristic.json";
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "CharacteristicArea",
  components: {
    TwoColumnImageAndDescription,
  },
  setup() {
    const state = reactive({
      characteristic: characteristic,
    });
    return { state };
  },
});
