
import TwoColumnImageAndDescription from "@/components/common/TwoColumnImageAndDescription.vue";
import GeneralTitle from "@/components/common/GeneralTitle.vue";
import SimpleLinkCarousel from "@/components/common/SimpleLinkCarousel.vue";
import aboutUberSheep from "@/data/about_uber_sheep.json";
import originalSongs from "@/data/original_songs.json";
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "AboutUberSheepArea",
  components: {
    GeneralTitle,
    TwoColumnImageAndDescription,
    SimpleLinkCarousel,
  },
  setup() {
    const state = reactive({
      aboutUberSheep: aboutUberSheep,
      originalSongs: originalSongs,
    });
    return { state };
  },
});
