import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c87493d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "music-list-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleLinkCarousel = _resolveComponent("SimpleLinkCarousel")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SimpleLinkCarousel, {
      title: "",
      contents: _ctx.state.originalSongs,
      navigation: false,
      pagination: false
    }, null, 8, ["contents"])
  ]))
}