
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "TwoColumnImageAndDescription",
  props: {
    contents: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const state = reactive({
      contents: props.contents,
    });
    return { state };
  },
});
