import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6822f4b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "music-list-area" }
const _hoisted_2 = { id: "original-songs" }
const _hoisted_3 = { id: "original-mini-songs" }
const _hoisted_4 = { id: "cover-songs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MusicCarousel = _resolveComponent("MusicCarousel")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MusicCarousel, {
        title: "Original songs",
        contents: _ctx.state.originalSongs,
        navigation: true,
        pagination: true
      }, null, 8, ["contents"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MusicCarousel, {
        title: "Original mini songs",
        contents: _ctx.state.originalMiniSongs,
        navigation: true,
        pagination: true
      }, null, 8, ["contents"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_MusicCarousel, {
        title: "Cover songs",
        contents: _ctx.state.coverSongs,
        navigation: true,
        pagination: true
      }, null, 8, ["contents"])
    ])
  ]))
}