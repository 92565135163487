<template>
  <section class="music-carousel">
    <h2 v-if="title">{{ title }}</h2>
    <Carousel
      :itemsToShow="3.95"
      :wrapAround="true"
      :breakpoints="data.breakpoints"
      :transition="600"
      :autoplay="3000"
    >
      <Slide v-for="(items, key) in state.contents" class="row" :key="key">
        <a :href="items.url" target="_blank">
          <img
            :src="require('@/assets/img/' + items.image)"
            :alt="items.title"
          />
        </a>
      </Slide>
      <template #addons>
        <Pagination v-if="pagination" />
        <Navigation v-if="navigation" />
      </template>
    </Carousel>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { defineComponent, reactive } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default defineComponent({
  name: "MusicCarousel",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    contents: {
      type: Object,
      default: () => ({}),
    },
    navigation: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      contents: props.contents,
    });
    const data = reactive({
      breakpoints: {
        200: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        400: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        // 700px and up
        750: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        1980: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        3000: {
          itemsToShow: 5,
          snapAlign: "center",
        },
      },
    });
    return { state, data };
  },
});
</script>
<style lang="scss" scoped>
.music-carousel {
  padding-bottom: 80px;
  h2 {
    color: #fff;
    margin: 10px;
    text-align: center;
    font-size: 32px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
<style lang="scss">
.carousel__item {
  min-height: 500px;
  width: 100%;
  background-color: #ffc57c;
  color: #fff;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel__pagination-button {
  background-color: #ffeccc;
}
.carousel__pagination-button--active {
  background-color: #ffc57c;
}
.carousel__slide {
  padding: 10px;
}
.carousel__prev,
.carousel__next {
  border-radius: 4px;
  background-color: #ffc57c;
  color: #001926;
  box-sizing: content-box;
  border: 5px solid #001926;
}
</style>
