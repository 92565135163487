<template>
  <div id="top-page" class="top-page">
    <TopMainVisualArea />
    <TopMusicList />
    <CharacteristicArea />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import TopMainVisualArea from "@/components/TopMainVisualArea.vue";
import CharacteristicArea from "@/components/CharacteristicArea.vue";
import TopMusicList from "@/components/TopMusicList.vue";
export default defineComponent({
  name: "TopPage",
  components: {
    TopMainVisualArea,
    CharacteristicArea,
    TopMusicList,
  },
  mounted() {
    document.title = "Home | 角巻わため非公式ファンサイトUber Sheep";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "ホロライブ所属の角巻わためさんの非公式パロディーファンサイト「Uber Sheep」のトップページです。"
      );
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        "ホロライブ所属の角巻わためさんの非公式パロディーファンサイト「Uber Sheep」のトップページです。"
      );
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", "website");
    const noindex = document.querySelector('[name="robots"]');
    if (noindex) {
      noindex.parentNode.removeChild(noindex);
    }
  },
});
</script>

<style lang="scss" scoped></style>
