
import originalSongs from "@/data/original_songs.json";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "MusicWatameArea",
  components: {},
  setup() {
    const state = reactive({
      originalSongs: originalSongs,
    });
    return { state };
  },
});
