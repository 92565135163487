<template>
  <div id="about-page" class="about-page">
    <AboutMainVisualArea />
    <AboutUberSheepArea />
    <AboutOwnerArea />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AboutMainVisualArea from "@/components/AboutMainVisualArea.vue";
import AboutUberSheepArea from "@/components/AboutUberSheepArea.vue";
import AboutOwnerArea from "@/components/AboutOwnerArea.vue";

export default defineComponent({
  name: "AboutPage",
  components: {
    AboutMainVisualArea,
    AboutUberSheepArea,
    AboutOwnerArea,
  },
  mounted() {
    document.title =
      "About Uber Sheep | 角巻わため非公式ファンサイトUber Sheep";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "ホロライブ所属の角巻わためさんの非公式パロディーファンサイト「Uber Sheep」のUber Sheepについてのページです。"
      );
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        "ホロライブ所属の角巻わためさんの非公式パロディーファンサイト「Uber Sheep」のUber Sheepについてのページです。"
      );
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", "article");
    const noindex = document.querySelector('[name="robots"]');
    if (noindex) {
      noindex.parentNode.removeChild(noindex);
    }
  },
});
</script>

<style lang="scss" scoped></style>
