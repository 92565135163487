
import GeneralTitle from "@/components/common/GeneralTitle.vue";
import SimpleLinkCarousel from "@/components/common/SimpleLinkCarousel.vue";
import originalSongs from "@/data/original_songs.json";
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "AboutOwnerArea",
  components: {
    GeneralTitle,
    SimpleLinkCarousel,
  },
  setup() {
    const state = reactive({
      originalSongs: originalSongs,
    });
    return { state };
  },
});
