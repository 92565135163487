<template>
  <div id="music-page" class="music-page">
    <MusicMainVisualArea />
    <MusicWatameArea />
    <MusicList />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MusicList from "@/components/MusicList.vue";
import MusicMainVisualArea from "@/components/MusicMainVisualArea.vue";
import MusicWatameArea from "@/components/MusicWatameArea.vue";
export default defineComponent({
  name: "MusicPage",
  components: { MusicMainVisualArea, MusicList, MusicWatameArea },
  mounted() {
    document.title = "Music | 角巻わため非公式ファンサイトUber Sheep";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "ホロライブ所属の角巻わためさんの非公式パロディーファンサイト「Uber Sheep」の角巻わための音楽活動についてのページです。"
      );
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        "ホロライブ所属の角巻わためさんの非公式パロディーファンサイト「Uber Sheep」の角巻わための音楽活動についてのページです。"
      );
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", "article");
    const noindex = document.querySelector('[name="robots"]');
    if (noindex) {
      noindex.parentNode.removeChild(noindex);
    }
  },
});
</script>

<style lang="scss" scoped>
.music-page {
  background-color: #001926;
}
</style>
