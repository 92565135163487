<template>
  <div id="about-site-page" class="about-site-page">
    <AboutSiteMainVisualArea />
    <AboutSiteArea />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AboutSiteMainVisualArea from "@/components/AboutSiteMainVisualArea.vue";
import AboutSiteArea from "@/components/AboutSiteArea.vue";

export default defineComponent({
  name: "AboutWebPage",
  components: {
    AboutSiteMainVisualArea,
    AboutSiteArea,
  },
  mounted() {
    document.title =
      "このサイトについて | 角巻わため非公式ファンサイトUber Sheep";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "ホロライブ所属の角巻わためさんの非公式パロディーファンサイト「Uber Sheep」です。"
      );
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        "ホロライブ所属の角巻わためさんの非公式パロディーファンサイト「Uber Sheep」です。"
      );
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", "article");
    const noindex = document.querySelector('[name="robots"]');
    if (noindex) {
      noindex.parentNode.removeChild(noindex);
    }
  },
});
</script>

<style lang="scss" scoped></style>
