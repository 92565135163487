
import { defineComponent, reactive } from "vue";
import MusicCarousel from "@/components/common/MusicCarousel.vue";
import originalSongs from "@/data/original_songs.json";
import originalMiniSongs from "@/data/original_mini_songs.json";
import coverSongs from "@/data/cover_songs.json";
export default defineComponent({
  name: "MusicList",
  components: { MusicCarousel },
  setup() {
    const state = reactive({
      originalSongs: originalSongs,
      originalMiniSongs: originalMiniSongs,
      coverSongs: coverSongs,
    });
    return { state };
  },
});
